<template>
  <div style="background-image: linear-gradient(#f3f2f5, #fff)">
    <div v-for="(itm, index) in items.section" :key="index">
    <header class="s-header-text" style v-if="itm.type == 'banner'">
      <div class="s-header-heading" data-effect="heading">
        <h3 class="f-st" style="opacity: 1;">{{itm.small_description}}</h3>
        <h2 class="f-hl" style>
          <div class="f-hl-line" style="display: block; text-align: start; position: relative;">
            {{itm.heading}}
            <!-- <em>
              <div
                style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1;"
                class="f-hl-word"
              >
              in
              </div>
            </em> -->
          </div>
          <!-- <div class="f-hl-line" style="display: block; text-align: start; position: relative;">
            <div
              style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1;"
              class="f-hl-word"
            >
              <div style="position:relative;display:inline-block;" class="f-hl-char">
                CRAFTSMANSHIP
              </div>
            </div>
            <em>
              <div
                style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1;"
                class="f-hl-word"
              >
                <div
                  style="position: relative; display: inline-block; opacity: 1; transform: translate(0px, 0px);"
                  class="f-hl-char"
                >and
                </div>
              </div>
            </em>
          </div>
          <div class="f-hl-line" style="display: block; text-align: start; position: relative;">
            <div
              style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1;"
              class="f-hl-word"
            >
              <div style="position:relative;display:inline-block;" class="f-hl-char">
                LUXURIOUS
              </div>
            </div>
            <div
              style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1;"
              class="f-hl-word"
            >
              <div style="position:relative;display:inline-block;" class="f-hl-char">
                DESIGN
              </div>
            </div>
          </div> -->
        </h2>
      </div>
      <button class="s-header-scroll">
        Scroll
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
      </button>
      <div class="s-header-background" data-background="#f6f4f2"></div>
    </header>
    <section class="s-banner" v-if="itm.type == 'categories'">
        <div class="s-banner-container a-left">
            <div class="s-banner-text" data-effect="block">
                <h3>{{itm.small_description}}</h3>
                <h2>{{itm.heading}}</h2>
                <p>{{itm.description}}
                </p>
            </div>
            <div class="s-banner-figure" v-if="itm.data">
                <div class="s-banner-src" id="s-banner-src1"> <img :src="$store.state.file_url + itm.data[0].banner[0].src + '?alt=media'" id="s-banner-src1-img" alt="Model posing long coat jacket"> </div>
                <div class="s-banner-src"> <img :src="$store.state.file_url + itm.data[1].banner[0].src + '?alt=media'" alt="Model posing in outside"> </div>
            </div>
            <div class="s-banner-background" data-background="#f3f2f5"></div>
        </div>
    </section>
    <section class="s-values" v-if="itm.type == 'card'"> 
      <div class="s-values-block" v-for="(itms, ind) in itm.data" :key="ind"> 
        <!-- <span class="f-st" style="transform-origin: 0px 0px; transform: translate(0px, 0px); opacity: 1;">Nº</span>  -->
        <figure class="f-nr">
          <div style="position: relative; display: inline-block; transform-origin: 0px 0px; transform: translate(0px, 0px); opacity: 1;">0</div>
          <div style="position: relative; display: inline-block; transform-origin: 0px 0px; transform: translate(0px, 0px); opacity: 1;">{{ind + 1}}</div>
        </figure> 
        <h2 class="f-hs" style="">
          <div style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate(0px, 0px); opacity: 1;">
            {{itms.heading}}
          </div>
          <div style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate(0px, 0px); opacity: 1;">
            <em>{{itms.small_description}}</em>
          </div>
        </h2> 
        <p class="f-pr" style="">
            {{itms.description}}
        </p> 
      </div> 
    </section>
  </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data(){
    return{

    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    document.getElementById('s-banner-src1').style.transform = 'matrix(1, 0, 0, 1, 0, 0.368895)'
  },
  methods:{
    handleScroll(){
      document.getElementById('s-banner-src1').style.transform = 'matrix(1, 0, 0, 1, 0, '+window.scrollY/5+')'
      document.getElementById('s-banner-src1-img').style.transform = 'scale('+window.scrollY/600+')';
    }
  }
}
</script>

<style scoped>
.s-banner h3{
    display: block;
    text-align: start;
    position: relative;
    transform-origin: 0px 0px;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    opacity: 0.8;
    font-weight: bold;
    font-size: 0.688vw;
    line-height: 0.688vw;
    margin-bottom: 3.75vw;
    letter-spacing: 0.188vw;
    text-transform: uppercase;
    font-family: 'Aktiv Grotesk';
}
.s-banner h2{
    font-weight: 300;
    font-size: 4.188vw;
    line-height: 4.5vw;
    margin-bottom: 3vw;
    letter-spacing: -0.179vw;
    font-family: 'Saol Display';
}
.s-banner p{
    opacity: 0.8;
    font-size: 0.938vw;
    font-weight: normal;
    line-height: 1.875vw;
    letter-spacing: 0.034vw;
    font-family: 'Aktiv Grotesk';
}
.f-nr {
    color: #ae97a2;
    font-weight: 300;
    font-size: 12.5vw;
    line-height: 9.688vw;
    letter-spacing: -0.665vw;
    font-family: 'Saol Display';
}
.f-hs {
    font-weight: 300;
    font-size: 2.688vw;
    line-height: 2.896vw;
    letter-spacing: -0.104vw;
    font-family: 'Saol Display';
}
.f-pr {
    opacity: 0.8;
    font-size: 0.938vw;
    font-weight: normal;
    line-height: 1.875vw;
    letter-spacing: 0.034vw;
    font-family: 'Aktiv Grotesk';
    text-align: justify;
}
.s-values .f-pr,
.s-banner-text p{
      width: calc(100vw / 15 * 3);
}
.s-values {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(100vw / 20 * 2);
    margin-right: calc(100vw / 20 * 2);
    padding-bottom: 16.25vw;
    margin-bottom: 0;
}

.s-header-text{
    display: flex;
    height: 50vh !important;
    align-items: flex-end;
}
.s-header-scroll{
    bottom: 1vw;
}

@media only screen and (max-width: 580px){
  .s-banner p{
    margin-left: calc(100vw / 12 * 1);
  }
  .s-values .f-pr, .s-banner-text p{
    width: calc(100vw / 12 * 8);
    font-size: 4.167vw;
    line-height: 7.5vw;
    letter-spacing: 0.15vw;
  }
  .s-banner h2 {
    font-size: 11.111vw;
    line-height: 11.944vw;
    margin-bottom: 8.333vw;
    letter-spacing: -0.417vw
  }
  .s-banner h3{
    font-size: 2.778vw;
    line-height: 4.167vw;
    margin-bottom: 5.556vw;
    letter-spacing: 0.75vw;
  }
  section, figure{
    padding: 13vw 0;
  }
  .s-values{
    display: block;
    flex-wrap: unset;
    margin-left: calc(100vw / 12 * 1);
    margin-right: calc(100vw / 12 * 1);
    padding-bottom: 16.25vw;
    margin-bottom: 0;
  }
  .s-values-block{
    width: auto;
    padding: 13.889vw 0;
    border-top: 1px solid #e8d6ca;
  }
  .s-values .f-nr{
    margin: 22.222vw 0 13.889vw;
    font-size: 55.556vw;
    line-height: 41.556vw;
    letter-spacing: -2.956vw;
  }
  .s-values .f-hs{
    margin-bottom: 6.944vw;
    font-size: 11.111vw;
    line-height: 11.944vw;
    letter-spacing: -0.417vw;
  }
  .s-values .f-pr{
    width: calc(100vw / 12 * 8);
    font-size: 4vw;
    line-height: 6.5vw;
    letter-spacing: 0.15vw;
    text-align: left;
  }
}
</style>
